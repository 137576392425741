import React from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import { graphql } from "gatsby"
import PostGrid from "../components/global/PostGrid"

import { DetectScroll } from "../utils/DetectScroll"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
export default function AllRecipes({ location, data }) {
  const posts = data.allSanityPost.nodes

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={data.sanityPages.seo.seoTitle}
        description={data.sanityPages.seo.seoDescription}
      />

      <section className="recipePage">
        <div className="titleWrapper">
          <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>
          <BlockContent
            blocks={data.sanityPages._rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent"
          />
        </div>
        <PostGrid posts={DetectScroll(posts)} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query blogQuery {
    allSanityPost(sort: { publishedAt: DESC }) {
      nodes {
        image: mainImage {
          asset {
            gatsbyImageData(
              backgroundColor: "#f9f9f9"
              placeholder: NONE
              width: 280
            )
          }
        }
        title
        slug {
          current
        }
      }
    }

    sanityPages(slug: { current: { eq: "blog" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }
  }
`
